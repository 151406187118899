@tailwind base;
@tailwind components;
@tailwind utilities;

*, body{
  font-family: "Montserrat", serif;
  font-optical-sizing: auto;
  font-weight: 400;
}

/* Add to your CSS file */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
